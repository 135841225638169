import React, { useMemo, useContext, useEffect } from 'react';

import { createHTTP, HttpInstance } from 'src/axios';
import { HttpContextProviderProps } from './HttpContext.models';

const HttpContext = React.createContext<HttpInstance>(createHTTP());

export function HttpContextProvider({ children, config, onInit }: HttpContextProviderProps) {
  const { baseURL, timeout, headers, credentials, appName, appVersion } = config;
  const http = useMemo(() => createHTTP(config), [baseURL, timeout, headers, credentials, appName, appVersion]);

  useEffect(() => {
    onInit && onInit(http);
  }, []);

  return <HttpContext.Provider value={http}>{children}</HttpContext.Provider>;
}

export default function useHttp(): HttpInstance {
  return useContext(HttpContext);
}
