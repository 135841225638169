import { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';

export enum CacheOptions {
  Enabled = 'force-cache',
  Disabled = 'no-store',
}

export type CreateHttpProps = {
  baseURL?: string;
  timeout?: number;
  headers?: { [key: string]: string | string[] | undefined };
  credentials?: RequestCredentials;
  appName?: string;
  appVersion?: string;
};

export type HttpClientOptions = {
  baseURL?: string;
  timeout?: number;
  headers?: AxiosHeaders;
  credentials?: RequestCredentials;
  cache?: CacheOptions;
} & AxiosRequestConfig;

export type HttpClientResponse<T> = {
  headers: AxiosResponse['headers'] | null;
  status: number | null;
  data: T | null;
  error: unknown | null;
  exception: unknown | null;
};
