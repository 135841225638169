import axios, { AxiosResponse, AxiosHeaders } from 'axios';
import httpStatus from 'http-status';

import { HttpClientOptions, HttpClientResponse, CreateHttpProps, CacheOptions } from './http-client.models';

const revalidate = 3600;
const defaultTimeout = 3000;
const defaultCredentials = 'same-origin';

export const defaultHeaders = {
  'Content-Type': 'application/json; charset=utf-8',
};

class HttpClient {
  private baseURL: HttpClientOptions['baseURL'];

  private headers: HttpClientOptions['headers'];

  private timeout: HttpClientOptions['timeout'];

  private credentials: HttpClientOptions['credentials'];

  constructor({
    baseURL = '',
    headers = new AxiosHeaders(defaultHeaders),
    timeout = defaultTimeout,
    credentials = defaultCredentials,
  }: Omit<HttpClientOptions, 'cache'>) {
    this.baseURL = baseURL;
    this.headers = headers;
    this.timeout = timeout;
    this.credentials = credentials;
  }

  // eslint-disable-next-line class-methods-use-this
  private handleSettingRequestHeaders = (
    key: string,
    value: string | number | boolean,
    requestHeaders: AxiosHeaders,
  ) => {
    if (typeof value === 'string' && value.includes('multipart/form-data')) {
      requestHeaders.delete(key);
    } else {
      requestHeaders.set(key, value);
    }
  };

  private handleHeaders = (
    headers: AxiosHeaders | Record<string, string | number | boolean> | undefined,
    cache: HttpClientOptions['cache'],
    requestHeaders: AxiosHeaders,
  ) => {
    this.handleSettingRequestHeaders(
      'Cache-Control',
      cache === CacheOptions.Enabled ? `max-age=${revalidate}` : 'no-store',
      requestHeaders,
    );

    if (!headers) {
      return;
    }

    if (headers instanceof AxiosHeaders) {
      headers.forEach((value, key) => {
        this.handleSettingRequestHeaders(key, value, requestHeaders);
      });
    } else {
      Object.entries(headers || {}).forEach(([key, value]) => {
        this.handleSettingRequestHeaders(key, value, requestHeaders);
      });
    }
  };

  private overrideOptions = ({
    baseURL,
    headers,
    timeout,
    credentials,
    cache,
    responseType,
  }: HttpClientOptions): HttpClientOptions => {
    const requestHeaders = new AxiosHeaders(this.headers);

    this.handleHeaders(headers, cache, requestHeaders);

    const requestOptions: HttpClientOptions = {
      baseURL: baseURL ?? this.baseURL,
      timeout: timeout ?? this.timeout,
      withCredentials: credentials ? credentials === 'include' : this.credentials === 'include',
      responseType,
      headers: requestHeaders,
    };

    return requestOptions;
  };

  // eslint-disable-next-line class-methods-use-this
  private async handleResponse<T>(response: AxiosResponse<T>): Promise<HttpClientResponse<T>> {
    const { headers, status, data } = response;

    return {
      headers,
      status,
      data,
      error: null,
      exception: null,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this
  private async handleError<T>(error: any): Promise<HttpClientResponse<T>> {
    const { headers, status, data } = error.response ?? {};

    if (status >= httpStatus.BAD_REQUEST) {
      return {
        headers,
        status,
        data: null,
        error: data,
        exception: null,
      };
    }

    return {
      headers: null,
      status: status ?? null,
      data: null,
      error: null,
      exception: error,
    };
  }

  axios = async <T>(url: string, options: HttpClientOptions): Promise<HttpClientResponse<T>> => {
    try {
      const response = await axios(url, options);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  };

  private async request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    endpoint: string,
    data?: BodyInit,
    options: HttpClientOptions = {},
  ): Promise<HttpClientResponse<T>> {
    const { baseURL, headers, timeout, withCredentials, responseType } = this.overrideOptions(options);
    return this.axios(`${baseURL}${endpoint}`, {
      method,
      data,
      headers,
      timeout,
      withCredentials,
      responseType,
    });
  }

  get = async <T>(endpoint: string, options: HttpClientOptions = {}): Promise<HttpClientResponse<T>> => {
    return this.request('GET', endpoint, undefined, options);
  };

  post = async <T>(
    endpoint: string,
    data: BodyInit,
    options: HttpClientOptions = {},
  ): Promise<HttpClientResponse<T>> => {
    return this.request('POST', endpoint, data, options);
  };

  put = async <T>(
    endpoint: string,
    data: BodyInit,
    options: HttpClientOptions = {},
  ): Promise<HttpClientResponse<T>> => {
    return this.request('PUT', endpoint, data, options);
  };

  patch = async <T>(
    endpoint: string,
    data: BodyInit,
    options: HttpClientOptions = {},
  ): Promise<HttpClientResponse<T>> => {
    return this.request('PATCH', endpoint, data, options);
  };

  delete = async <T>(endpoint: string, options: HttpClientOptions = {}): Promise<HttpClientResponse<T>> => {
    return this.request('DELETE', endpoint, undefined, options);
  };

  setHeader = (name: string, value: string): void => {
    this.headers!.set(name, value);
  };
}

export default function createHTTP({
  baseURL = '',
  timeout = defaultTimeout,
  headers = {},
  credentials = defaultCredentials,
  appName = '',
  appVersion = '',
}: CreateHttpProps = {}): HttpInstance {
  const requestHeaders = new AxiosHeaders({
    ...defaultHeaders,
    ...headers,
  });

  appName && requestHeaders.set('X-App-Name', appName);
  appVersion && requestHeaders.set('X-App-Version', appVersion);

  return new HttpClient({
    headers: requestHeaders,
    baseURL,
    timeout,
    credentials,
  });
}

export type HttpInstance = HttpClient;
