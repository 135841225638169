'use client';

import { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTracker } from '@grupa-pracuj/tracker-relax';
import { CurrentStepInAuthProcessPayload } from '@grupa-pracuj/tracker-relax/dist/events';
import { UseTrackerResponse } from '@grupa-pracuj/tracker-relax/dist/useTracker';

import useQueryParameters, { QueryParameters } from 'src/hooks/use-query-parameters';

import useAnalyticsV3, { Analytics } from 'src/analytics/AnalyticsContext/AnalyticsContext';

export type UseAnalyticsReturn = CurrentStepInAuthProcessPayload & {
  v2: Pick<
    UseTrackerResponse,
    | 'sendCurrentStepInAuthProcess'
    | 'sendCurrentStepInApplicationProcess'
    | 'sendRegistrationInformation'
    | 'setTrackerCallback'
    | 'sendWebVitals'
    | 'sendPageView'
    | 'sendPageViewTestGroup'
    | 'setTrackerDefaults'
  >;
  v3: Analytics;
} & Pick<QueryParameters, 'oneClickApplyStatus' | 'applicationProcessId'> & {
    emailOriginIdParam: string | null;
    offerId: number | null;
    attachedCV: number;
  };

// TODO: Remove after migration to v3 and use Query Parameters directly
export default function useAnalytics(): UseAnalyticsReturn {
  const {
    authProcessId,
    processCurrentStage,
    accountType,
    processType,
    offerId,
    applicationProcessId,
    attachedCV,
    oneClickApplyStatus,
    emailOriginId: emailOriginIdParam,
  } = useQueryParameters();
  const v2 = useTracker();
  const v3 = useAnalyticsV3();
  const generateAuthProcessId = useMemo(() => uuidv4(), []);
  const isApplicationProcess = !!applicationProcessId && !!offerId;

  // reset callback on unmount
  useEffect(() => {
    // @eslint-disable-next-line consistent-return
    return () => {
      v2.setTrackerCallback(() => null);
    };
  }, [v2.setTrackerCallback]);

  return {
    v2,
    v3,
    authProcessId: authProcessId ?? generateAuthProcessId,
    processCurrentStage: processCurrentStage as CurrentStepInAuthProcessPayload['processCurrentStage'],
    accountType: accountType as CurrentStepInAuthProcessPayload['accountType'],
    processType: processType as CurrentStepInAuthProcessPayload['processType'],
    emailOriginIdParam,
    isApplicationProcess,
    offerId: offerId ? Number(offerId) : null,
    attachedCV: attachedCV ? Number(attachedCV) : 0,
    oneClickApplyStatus,
    applicationProcessId,
  };
}
